import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'

import Container from '~/components/Container'
import BuyButton from '~/components/BuyButton'
import NotchedCard from '~/components/NotchedCard'
import Features from '~/components/Features'
import ImageGallery from '~/components/ImageGallery'
import IconArrow from '~/components/icons/IconArrow'
import IconSun from '~/components/icons/IconSun'
import AppCTAs from '~/components/AppCTAs'

const title = 'Mary Agrotechnologies'
const description =
  'Mary Model Z, the first and only grow box created for your living room. Smart. Automated. Effortless. Model Z is the automated home grow box that takes the guesswork out of growing. The uncompromising and compact design empowers anyone to grow what they want, when they want, and where they want.'

const appFeatures = [
  {
    title: 'Live Support',
    description:
      'Have access to all of our best growing tips in one place! Our advice is designed to help you get the most out of your growing experience.'
  },
  {
    title: 'Track Your Growth',
    description:
      'Designed to eliminate the guesswork and give you access to the information that you need to track your grow in real-time.'
  },
  {
    title: 'Growing Advice',
    description:
      'Need some guidance? Our app offers tips and reminders to show you how to care for your plant.'
  },
  {
    title: 'Real Time Monitoring',
    description:
      'Check in on your plant size and growing conditions any time, from any where.'
  }
]

export const getStaticProps: GetStaticProps = async () => {
  const data = {
    image_gallery: [
      { src: '/images/gallery-image-1.png' },
      { src: '/images/gallery-image-2.png' },
      { src: '/images/gallery-image-3.png' },
      { src: '/images/gallery-image-4.png' },
      { src: '/images/gallery-image-5.png' },
      { src: '/images/gallery-image-6.png' }
    ],
    product_overview: {
      body: 'Simple, automated, and designed to look good in your home. Find out how anyone can learn how to grow 3 ounces at home, effortlessly.'
    },
    ecosystem_section: {
      title: 'An Entire Ecosystem in a Box, in the Comfort of Your Living Room',
      section_1: {
        title:
          'Maintaining a controlled and healthy environment is crucial when it comes to fostering healthy plants. When you grow with Mary, everything is taken care of for you.',
        content:
          'The Mary Model Z grow box automatically maintains the ideal environment for your plant at each stage of growth.',
        imageSrc: '/images/grow-box-living-room.jpg'
      },
      section_2: {
        title:
          'Equipped with a climate controlled environment, water tank, and multi-directional lighting to simulate the sun.',
        content:
          'Mary will provide your plant with everything it needs while fitting in with any living space.',
        imageSrc: '/images/grow-box-tomato-plant.jpg'
      }
    }
  }
  return { props: { data } }
}

export default function IndexPage(props) {
  return (
    <Container className="flex-col bg-grey-700">
      <NextSeo title={title} description={description} />
      <section className="bg-grey-700 max-w-7xl mx-auto flex flex-col pt-20 md:pt-28 lg:pt-40">
        <div className="font-medium pl-6 md:hidden text-2xl text-white z-10 w-4/5">
          The Future of Clean, Indoor Growing
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center z-10 order-2 md:order-1">
          <div className="hidden md:block md:text-4xl lg:text-5xl font-medium text-white md:pl-8">
            The Future of Clean, Indoor Growing
          </div>
          <div className="flex flex-col px-4 md:pl-16 pb-12 lg:pr-14">
            <p className="text-white opacity-70 text-base pb-12 md:pb-4 md:w-4/5 xl:w-full">
              {props.data.product_overview.body}
            </p>
            <Link href="/products/model-z" passHref>
              <div className="flex flex-row items-center cursor-pointer">
                <a className="text-white text-sm pr-4 ">Explore Grow Box</a>
                <IconArrow direction="right" strokeWidth="2" colour="white" />
              </div>
            </Link>
          </div>
        </div>
        <div className="w-full xl:w-76 aspect-w-16 aspect-h-9 pb-16 md:pb-28 -mt-4 sm:-mt-6 lg:-mt-8  z-0 order-1 md:order-2">
          <Image
            src="/images/home-header.jpg"
            alt="MaryAG"
            width="150%"
            height="100%"
            layout="responsive"
            objectFit="contain"
          />
        </div>
      </section>

      <section className="flex flex-col relative">
        <div className="mx-auto bg-grey-600 w-full sm:w-3/5 lg:w-4/5 z-10 max-w-7xl">
          <div className="flex flex-col lg:flex-row">
            <div className="flex-1 sm:p-6 lg:py-4 lg:pl-4 lg:pr-18 xl:py-8 xl:pl-8 xl:pr-32">
              <div className="aspect-h-13 aspect-w-10">
                <NotchedCard
                  notchPosition="top-right"
                  className="hidden md:block"
                  aspectW={461}
                  aspectH={600}
                >
                  <Image
                    src="/images/model-z.jpg"
                    width="461"
                    height="600"
                    layout="responsive"
                    objectFit="cover"
                    alt="grow-box"
                    className="rounded-md"
                  />
                </NotchedCard>
                <div className="bg-black md:hidden">
                  <NotchedCard
                    notchPosition="top-left"
                    aspectW={320}
                    aspectH={426}
                  >
                    <Image
                      src="/images/model-z.jpg"
                      width="320"
                      height="426"
                      layout="responsive"
                      objectFit="cover"
                      alt="grow-box"
                    />
                  </NotchedCard>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-center text-white p-6 pt-16 lg:p-0">
              <h1 className="text-xl leading-10 xl:text-3xl sm:w-4/5">
                Say Hello to Mary Model Z
              </h1>
              <p className="text-base opacity-70 pt-12 sm:w-4/5">
                A fully automated single plant enclosure that will teach you how
                to care for your plant and get the most out of every harvest.
                This climate controlled smart grow box will help you turn any
                living space into an ideal growing environment for the plant of
                your choice.
              </p>
              <div className="self-start pt-16">
                {/* <BuyButton
                  className="bg-pink-800 rounded-full px-6 py-4 lg:py-4 lg:px-8 text-sm text-grey-700 "
                  buttonText="Add To Cart - "
                  showPrice={true}
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grey-700 absolute top-0 bottom-1/2 left-0 right-0 z-0"></div>
        <div className="bg-taupe-300 absolute bottom-0 top-1/2 left-0 right-0 z-0"></div>
      </section>

      <section className="bg-taupe-300 py-16 lg:py-48 text-black lg:px-28 ">
        <div className="max-w-7xl mx-auto flex flex-col">
          <h2 className="text-xl leading-10 font-medium capitalize sm:text-2xl md:text-3xl xl:text-4xl pb-4 mx-auto z-10 px-6 lg:px-0 pt-16 lg:pt-0">
            {props.data.ecosystem_section.title}
          </h2>
          <div className="flex flex-col lg:grid grid-flow-row grid-rows-2 grid-cols-12 -mt-12">
            <div className="w-full col-span-6">
              <div className="lg:-ml-28">
                <Image
                  src={props.data.ecosystem_section.section_1.imageSrc}
                  width={710}
                  height={532}
                  layout="responsive"
                  objectFit="cover"
                  alt="Grow Box Ecosystem Setting"
                />
              </div>
            </div>
            <div className="col-span-1" />
            <div className="col-span-5 xl:w-4/5 px-6 lg:px-0">
              <h3 className="text-grey-700 text-lg pt-16 lg:pt-18 xl:pt-32">
                {props.data.ecosystem_section.section_1.title}
              </h3>
              <p className="text-grey-700 opacity-70 text-base pt-12 pb-12 lg:pb-0">
                {props.data.ecosystem_section.section_1.content}
              </p>
            </div>
            <div className="col-span-5 lg:-mt-12 xl:-mt-0 order-2 lg:order-none pt-16 lg:pt-0 px-6 lg:px-0">
              <IconSun />
              <h3 className="text-grey-700 text-xl pb-12 pt-12 leading-10">
                {props.data.ecosystem_section.section_2.title}
              </h3>
              <p className="text-grey-700 opacity-70 text-base lg:pb-56 w-4/5">
                {props.data.ecosystem_section.section_2.content}
              </p>
            </div>
            <div className="col-span-2" />
            <NotchedCard
              notchPosition="top-left"
              className="col-span-5 order-1 lg:order-none"
              aspectW={488}
              aspectH={650}
            >
              <Image
                src={props.data.ecosystem_section.section_2.imageSrc}
                width={488}
                height={650}
                layout="responsive"
                objectFit="cover"
                alt="Grow Box Ecosystem Plant"
              />
            </NotchedCard>
          </div>
        </div>
      </section>
      <section className="bg-grey-700 mx-auto max-w-7xl p-6 pt-16 flex flex-col gap-y-16 mb-6 md:flex-row md:items-center md:gap-x-8">
        <ImageGallery {...props} />
      </section>

      <div className="lg:px-28">
        <AppCTAs />
        <Features features={appFeatures} type="app" />
      </div>
    </Container>
  )
}
